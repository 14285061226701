/* src/components/HamburgerMenu.css */
.hamburger-menu-container {
    position: relative;
    z-index: var(--z-index-modal);
    display: flex;
    align-items: center;
    justify-content: center;
}

.hamburger-button {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 24px;
    height: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: var(--z-index-modal);
}

.hamburger-button span {
    width: 100%;
    height: 2px;
    background-color: var(--viterbo-white);
    border-radius: 2px;
    transition: all 0.3s ease;
}

.hamburger-button.open span:nth-child(1) {
    transform: translateY(10.5px) rotate(45deg);
}

.hamburger-button.open span:nth-child(2) {
    opacity: 0;
}

.hamburger-button.open span:nth-child(3) {
    transform: translateY(-10.5px) rotate(-45deg);
}

.menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: var(--z-index-modal-backdrop);
}

.menu-content {
    position: fixed;
    top: 0;
    right: -100%;
    width: 80%;
    max-width: 320px;
    height: 100%;
    background-color: var(--viterbo-white);
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    transition: right 0.3s ease;
    display: flex;
    flex-direction: column;
    z-index: var(--z-index-modal);
    overflow-y: auto;
}

.menu-content.open {
    right: 0;
}

.menu-header {
    display: flex;
    align-items: center;
    padding: var(--spacing-md);
    background-color: var(--viterbo-blue);
    color: var(--viterbo-white);
    position: relative;
}

.menu-logo {
    width: 40px;
    height: 40px;
    margin-right: var(--spacing-sm);
    background-color: var(--viterbo-white);
    border-radius: 50%;
    padding: 4px;
}

.menu-header h2 {
    font-size: var(--font-size-lg);
    margin: 0;
}

.close-menu-button {
    position: absolute;
    right: 8px; 
    top: 8px; 
    background: none;
    border: none;
    color: #FFFFFF; 
    cursor: pointer;
    width: 44px; 
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.2s ease-in-out;
    margin-top: 5px;
    z-index: 1100; 
}
  
.close-menu-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
}
/* Add a fallback "X" character that shows if SVG fails */
.close-menu-button::before {
    content: "";
}
/* Show the text "X" if SVG doesn't render properly */
.close-menu-button svg:not(:defined) + .close-menu-button::before {
    opacity: 1;
}

/* User info section in menu */
.menu-user-info {
    padding: var(--spacing-md);
    border-bottom: 1px solid var(--viterbo-light-gray);
    background-color: var(--viterbo-off-white);
}

.user-email {
    font-size: var(--font-size-sm);
    color: var(--viterbo-blue);
    font-weight: var(--font-weight-medium);
    overflow: hidden;
    text-overflow: ellipsis;
}

.menu-nav {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: var(--spacing-md);
}

.menu-nav a {
    color: var(--viterbo-blue);
    text-decoration: none;
    padding: var(--spacing-md);
    border-bottom: 1px solid var(--viterbo-light-gray);
    font-weight: var(--font-weight-medium);
    display: flex;
    align-items: center;
    transition: background-color var(--transition-normal);
}

.menu-nav a:hover {
    background-color: var(--viterbo-off-white);
}

.menu-nav a:active {
    background-color: var(--viterbo-light-gray);
}

.menu-footer {
    padding: var(--spacing-md);
    text-align: center;
    color: var(--viterbo-gray);
    font-size: var(--font-size-sm);
    border-top: 1px solid var(--viterbo-light-gray);
    margin-top: auto; 
}

/* Logout button styling */
.menu-logout-button {
    background-color: var(--viterbo-red);
    color: var(--viterbo-white);
    padding: var(--spacing-md);
    border: none;
    border-radius: var(--border-radius-md);
    width: 100%;
    font-size: var(--font-size-base);
    cursor: pointer;
    margin-bottom: var(--spacing-md);
    font-weight: var(--font-weight-medium);
    transition: background-color var(--transition-normal), transform var(--transition-fast);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-sm);
}

.menu-logout-button:hover {
    background-color: var(--viterbo-red-light);
    transform: translateY(-2px);
}

.menu-logout-button:active {
    transform: translateY(0);
}

/* Hide hamburger menu on desktop */
@media (min-width: 768px) {
    .hamburger-menu-container {
        display: flex;
    }
}

@supports (-webkit-touch-callout: none) {
    .close-menu-button {
        transform: translateZ(0); /* Force hardware acceleration */
        -webkit-transform: translateZ(0);
    }
    
    .close-menu-button svg {
        stroke: #FFFFFF !important; /* Force white stroke color */
    }
}