/* src/components/EventCarousel.css */
.event-carousel {
    margin-bottom: var(--spacing-md);
    width: 100%;
  }
  
  .carousel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-md);
  }
  
  .carousel-header h3 {
    margin: 0;
    color: var(--viterbo-blue);
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
  }
  
  .carousel-header svg {
    color: var(--viterbo-red);
  }
  
  .view-all-link {
    font-size: var(--font-size-sm);
    color: var(--viterbo-red);
    text-decoration: none;
    font-weight: var(--font-weight-medium);
    transition: color var(--transition-normal);
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
  }
  
  .view-all-link:hover {
    color: var(--viterbo-red-dark);
    text-decoration: underline;
  }
  
  .view-all-link svg {
    width: 16px;
    height: 16px;
  }
  
  .carousel-container {
    position: relative;
    border-radius: var(--border-radius-lg);
    overflow: hidden;
    box-shadow: var(--shadow-md);
    cursor: pointer;
    transition: transform var(--transition-normal), box-shadow var(--transition-normal);
    background-color: var(--viterbo-white);
  }
  
  .carousel-container:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-lg);
  }
  
  .carousel-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
    box-shadow: var(--shadow-md);
    transition: background-color var(--transition-normal);
  }
  
  .carousel-nav:hover {
    background-color: var(--viterbo-white);
  }
  
  .carousel-prev {
    left: 10px;
  }
  
  .carousel-next {
    right: 10px;
  }
  
  .carousel-event {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .event-image-container {
    width: 100%;
    height: 180px;
    overflow: hidden;
  }
  
  .event-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform var(--transition-normal);
  }
  
  .carousel-container:hover .event-image {
    transform: scale(1.05);
  }
  
  .event-info {
    padding: var(--spacing-md);
    background-color: var(--viterbo-white);
  }
  
  .event-title {
    margin: 0 0 var(--spacing-sm) 0;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bold);
    color: var(--viterbo-blue);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .event-date-time {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    font-size: var(--font-size-sm);
    color: var(--viterbo-gray);
  }
  
  .carousel-indicators {
    display: flex;
    justify-content: center;
    padding: var(--spacing-sm);
    gap: var(--spacing-sm);
    background-color: var(--viterbo-white);
  }
  
  .carousel-indicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--viterbo-light-gray);
    cursor: pointer;
    transition: background-color var(--transition-normal), transform var(--transition-normal);
  }
  
  .carousel-indicator.active {
    background-color: var(--viterbo-red);
    transform: scale(1.25);
  }
  
  .carousel-loading,
  .carousel-error,
  .carousel-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-xl);
    background-color: var(--viterbo-white);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--shadow-md);
    min-height: 200px;
  }
  
  .carousel-loading .loading-spinner {
    margin-bottom: var(--spacing-md);
  }
  
  .carousel-error {
    color: var(--viterbo-red);
  }
  
  .carousel-empty {
    color: var(--viterbo-gray);
  }
  
  /* Responsive adjustments */
  @media (min-width: 768px) {
    .event-image-container {
      height: 220px;
    }
  }
  
  @media (max-width: 480px) {
    .carousel-nav {
      width: 32px;
      height: 32px;
    }
    
    .event-image-container {
      height: 160px;
    }
    
    .event-info {
      padding: var(--spacing-sm);
    }
    
    .event-title {
      font-size: var(--font-size-base);
    }
  }