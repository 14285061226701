/* src/components/IFrameWrapper.css */
.iframe-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: var(--viterbo-white);
  }
  
  .iframe-content {
    flex: 1;
    position: relative;
    overflow: hidden;
  }
  
  .iframe-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--viterbo-white);
    z-index: 1;
  }
  
  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(0, 38, 81, 0.2);
    border-top-color: var(--viterbo-blue);
    border-radius: 50%;
    animation: spin 1s ease-in-out infinite;
    margin-bottom: var(--spacing-md);
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  
  iframe {
    width: 100%;
    height: 100%;
    border: none;
    display: block;
  }
  
  iframe.loading {
    opacity: 0;
  }
  
  /* Mobile navigation and dashboard header styles are imported from Dashboard.css */