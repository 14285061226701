.signin-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-direction: column;
  background-color: var(--viterbo-off-white);
  padding: var(--spacing-md);
}

.signin-card {
  background: var(--viterbo-white);
  padding: var(--spacing-xl);
  border-radius: var(--border-radius-lg);
  max-width: 400px;
  width: 100%;
  box-shadow: var(--shadow-lg);
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.signin-card header {
  margin-bottom: var(--spacing-xl);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signin-card img {
  width: 120px;
  margin-bottom: var(--spacing-md);
  transition: transform var(--transition-normal);
}

.signin-card img:hover {
  transform: scale(1.05);
}

.signin-card h1 {
  color: var(--viterbo-blue);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--spacing-md);
}

.signin-error {
  color: var(--viterbo-red);
  font-size: var(--font-size-sm);
  margin-bottom: var(--spacing-md);
  padding: var(--spacing-sm);
  background-color: rgba(158, 27, 52, 0.1);
  border-radius: var(--border-radius-md);
  animation: shake 0.5s ease-in-out;
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  20%, 60% { transform: translateX(-5px); }
  40%, 80% { transform: translateX(5px); }
}

.btn-sso {
  background-color: var(--viterbo-blue);
  color: var(--viterbo-white);
  padding: var(--spacing-md) var(--spacing-md);
  border: none;
  border-radius: var(--border-radius-md);
  width: 100%;
  font-size: var(--font-size-base);
  cursor: pointer;
  margin-bottom: var(--spacing-sm);
  font-weight: var(--font-weight-medium);
  transition: background-color var(--transition-normal), transform var(--transition-fast);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
}

.btn-sso svg {
  width: 20px;
  height: 20px;
}

.btn-sso:hover {
  background-color: var(--viterbo-blue-light);
  transform: translateY(-2px);
}

.btn-sso:active {
  transform: translateY(0);
}

.bypass-link {
  color: var(--viterbo-red);
  cursor: pointer;
  font-size: var(--font-size-sm);
  text-decoration: none;
  display: inline-block;
  margin-top: var(--spacing-md);
  font-weight: var(--font-weight-medium);
  transition: color var(--transition-normal);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius-sm);
}

.bypass-link:hover {
  color: var(--viterbo-red-light);
  text-decoration: underline;
}

.bypass-input {
  width: 100%;
  padding: var(--spacing-md);
  margin-bottom: var(--spacing-md);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--viterbo-light-gray);
  font-size: var(--font-size-base);
  transition: border-color var(--transition-normal), box-shadow var(--transition-normal);
}

.bypass-input:focus {
  outline: none;
  border-color: var(--viterbo-blue-light);
  box-shadow: 0 0 0 3px rgba(0, 38, 81, 0.2);
}

.btn-bypass {
  background-color: var(--viterbo-blue);
  color: var(--viterbo-white);
  padding: var(--spacing-md) var(--spacing-md);
  border: none;
  border-radius: var(--border-radius-md);
  width: 100%;
  font-size: var(--font-size-base);
  cursor: pointer;
  margin-top: var(--spacing-sm);
  font-weight: var(--font-weight-medium);
  transition: background-color var(--transition-normal), transform var(--transition-fast);
}

.btn-bypass:hover {
  background-color: var(--viterbo-blue-light);
  transform: translateY(-2px);
}

.btn-bypass:active {
  transform: translateY(0);
}

/* Loading state indicator */
.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s ease-in-out infinite;
  margin-right: var(--spacing-sm);
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .signin-card {
    padding: var(--spacing-lg);
  }
  
  .signin-card h1 {
    font-size: var(--font-size-lg);
  }
}