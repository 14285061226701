/* InstallButton.css */

.btn-install {
  background-color: var(--viterbo-red);
  color: var(--viterbo-white);
  padding: var(--spacing-md) var(--spacing-md);
  border: none;
  border-radius: var(--border-radius-md);
  width: 100%;
  font-size: var(--font-size-base);
  cursor: pointer;
  margin: var(--spacing-sm) 0;
  font-weight: var(--font-weight-medium);
  transition: background-color var(--transition-normal), transform var(--transition-fast);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
}

.btn-install svg {
  width: 20px;
  height: 20px;
}

.btn-install:hover {
  background-color: var(--viterbo-red-light);
  transform: translateY(-2px);
}

.btn-install:active {
  transform: translateY(0);
}

.btn-install:disabled {
  background-color: var(--viterbo-gray);
  cursor: not-allowed;
  transform: none;
}

.install-button-container {
  width: 100%;
  max-width: 400px;
  margin: var(--spacing-md) 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* New iOS Modal Styles */
.ios-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--z-index-modal);
  padding: var(--spacing-md);
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.ios-modal {
  background-color: var(--viterbo-white);
  border-radius: var(--border-radius-lg);
  width: 100%;
  max-width: 400px;
  box-shadow: var(--shadow-xl);
  overflow: hidden;
  animation: scaleIn 0.3s ease-in-out;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

@keyframes scaleIn {
  from { transform: scale(0.9); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}

.ios-modal-header {
  background-color: var(--viterbo-blue);
  color: var(--viterbo-white);
  padding: var(--spacing-md) var(--spacing-lg);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ios-modal-header h3 {
  margin: 0;
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
}

.ios-modal-close {
  background: none;
  border: none;
  color: var(--viterbo-white);
  cursor: pointer;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-full);
  transition: background-color var(--transition-normal);
}

.ios-modal-close:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.ios-modal-body {
  padding: var(--spacing-lg);
  overflow-y: auto;
}

/* Removed app icon styles */

.ios-instructions-steps {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.ios-step {
  display: flex;
  gap: var(--spacing-md);
}

.ios-step-number {
  background-color: var(--viterbo-red);
  color: var(--viterbo-white);
  width: 32px;
  height: 32px;
  border-radius: var(--border-radius-full);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-lg);
  flex-shrink: 0;
}

.ios-step-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ios-step-content p {
  margin: 0 0 var(--spacing-sm);
  font-size: var(--font-size-base);
  color: var(--viterbo-blue);
  text-align: left;
  width: 100%;
}

.ios-share-icon,
.ios-add-icon,
.ios-confirm-icon {
  background-color: var(--viterbo-off-white);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-sm);
  color: var(--viterbo-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--spacing-xs);
  align-self: flex-start;
}

.ios-modal-footer {
  padding: var(--spacing-md);
  border-top: 1px solid var(--viterbo-light-gray);
  display: flex;
  justify-content: center;
}

.ios-modal-button {
  background-color: var(--viterbo-red);
  color: var(--viterbo-white);
  padding: var(--spacing-md) var(--spacing-xl);
  border: none;
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: background-color var(--transition-normal), transform var(--transition-fast);
}

.ios-modal-button:hover {
  background-color: var(--viterbo-red-light);
  transform: translateY(-2px);
}

.ios-modal-button:active {
  transform: translateY(0);
}

/* Loading state indicator */
.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s ease-in-out infinite;
  margin-right: var(--spacing-sm);
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .ios-modal {
    max-height: 85vh;
  }
  
  .ios-modal-header h3 {
    font-size: var(--font-size-md);
  }
  
  .ios-modal-body {
    padding: var(--spacing-md);
  }
}

/* For very small screens */
@media (max-width: 320px) {
  .ios-step {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .ios-step-content p {
    margin-top: var(--spacing-xs);
  }
}