/* Viterbo App Design System */

:root {
    /* Color palette based on Viterbo colors */
    --viterbo-red: #9E1B34;
    --viterbo-red-light: #C13A53;
    --viterbo-red-dark: #7A1427;
    --viterbo-blue: #002651;
    --viterbo-blue-light: #003A7F;
    --viterbo-blue-dark: #001A38;
    --viterbo-white: #FFFFFF;
    --viterbo-off-white: #F5F7FA;
    --viterbo-light-gray: #E9ECEF;
    --viterbo-gray: #6C757D;
    --viterbo-dark-gray: #343A40;
    --viterbo-black: #212529;
    
    /* Typography */
    --font-family-base: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    --font-weight-normal: 400;
    --font-weight-medium: 500;
    --font-weight-bold: 700;
    
    /* Font sizes */
    --font-size-xs: 0.75rem;   /* 12px */
    --font-size-sm: 0.875rem;  /* 14px */
    --font-size-base: 1rem;    /* 16px */
    --font-size-lg: 1.125rem;  /* 18px */
    --font-size-xl: 1.25rem;   /* 20px */
    --font-size-2xl: 1.5rem;   /* 24px */
    --font-size-3xl: 1.875rem; /* 30px */
    --font-size-4xl: 2.25rem;  /* 36px */
    
    /* Spacing */
    --spacing-xs: 0.25rem;   /* 4px */
    --spacing-sm: 0.5rem;    /* 8px */
    --spacing-md: 1rem;      /* 16px */
    --spacing-lg: 1.5rem;    /* 24px */
    --spacing-xl: 2rem;      /* 32px */
    --spacing-2xl: 2.5rem;   /* 40px */
    --spacing-3xl: 3rem;     /* 48px */
    
    /* Border radius */
    --border-radius-sm: 0.25rem;  /* 4px */
    --border-radius-md: 0.5rem;   /* 8px */
    --border-radius-lg: 0.75rem;  /* 12px */
    --border-radius-xl: 1rem;     /* 16px */
    --border-radius-full: 9999px; /* Full rounded */
    
    /* Shadows */
    --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    --shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    
    /* Transitions */
    --transition-fast: 0.1s ease-in-out;
    --transition-normal: 0.2s ease-in-out;
    --transition-slow: 0.3s ease-in-out;
    
    /* Z-index */
    --z-index-dropdown: 1000;
    --z-index-sticky: 1020;
    --z-index-fixed: 1030;
    --z-index-modal-backdrop: 1040;
    --z-index-modal: 1050;
    --z-index-popover: 1060;
    --z-index-tooltip: 1070;
  }
  
  /* Base styles */
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: var(--font-family-base);
    font-size: 16px;
    color: var(--viterbo-black);
    background-color: var(--viterbo-white);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  #root {
    height: 100%;
  }
  
  * {
    box-sizing: border-box;
  }
  
  /* Utility classes */
  .text-center { text-align: center; }
  .text-left { text-align: left; }
  .text-right { text-align: right; }
  
  .text-red { color: var(--viterbo-red); }
  .text-blue { color: var(--viterbo-blue); }
  .text-white { color: var(--viterbo-white); }
  .text-gray { color: var(--viterbo-gray); }
  
  .bg-red { background-color: var(--viterbo-red); }
  .bg-blue { background-color: var(--viterbo-blue); }
  .bg-white { background-color: var(--viterbo-white); }
  .bg-off-white { background-color: var(--viterbo-off-white); }
  .bg-light-gray { background-color: var(--viterbo-light-gray); }
  
  .font-normal { font-weight: var(--font-weight-normal); }
  .font-medium { font-weight: var(--font-weight-medium); }
  .font-bold { font-weight: var(--font-weight-bold); }
  
  .text-xs { font-size: var(--font-size-xs); }
  .text-sm { font-size: var(--font-size-sm); }
  .text-base { font-size: var(--font-size-base); }
  .text-lg { font-size: var(--font-size-lg); }
  .text-xl { font-size: var(--font-size-xl); }
  .text-2xl { font-size: var(--font-size-2xl); }
  
  .mt-0 { margin-top: 0; }
  .mt-xs { margin-top: var(--spacing-xs); }
  .mt-sm { margin-top: var(--spacing-sm); }
  .mt-md { margin-top: var(--spacing-md); }
  .mt-lg { margin-top: var(--spacing-lg); }
  .mt-xl { margin-top: var(--spacing-xl); }
  
  .mb-0 { margin-bottom: 0; }
  .mb-xs { margin-bottom: var(--spacing-xs); }
  .mb-sm { margin-bottom: var(--spacing-sm); }
  .mb-md { margin-bottom: var(--spacing-md); }
  .mb-lg { margin-bottom: var(--spacing-lg); }
  .mb-xl { margin-bottom: var(--spacing-xl); }
  
  .mx-auto { margin-left: auto; margin-right: auto; }
  .my-auto { margin-top: auto; margin-bottom: auto; }
  
  .p-xs { padding: var(--spacing-xs); }
  .p-sm { padding: var(--spacing-sm); }
  .p-md { padding: var(--spacing-md); }
  .p-lg { padding: var(--spacing-lg); }
  .p-xl { padding: var(--spacing-xl); }
  
  .rounded-sm { border-radius: var(--border-radius-sm); }
  .rounded-md { border-radius: var(--border-radius-md); }
  .rounded-lg { border-radius: var(--border-radius-lg); }
  .rounded-full { border-radius: var(--border-radius-full); }
  
  .shadow-sm { box-shadow: var(--shadow-sm); }
  .shadow-md { box-shadow: var(--shadow-md); }
  .shadow-lg { box-shadow: var(--shadow-lg); }
  .shadow-xl { box-shadow: var(--shadow-xl); }
  
  .flex { display: flex; }
  .flex-col { flex-direction: column; }
  .flex-row { flex-direction: row; }
  .items-center { align-items: center; }
  .justify-center { justify-content: center; }
  .justify-between { justify-content: space-between; }
  .flex-1 { flex: 1; }
  .flex-grow { flex-grow: 1; }
  .flex-wrap { flex-wrap: wrap; }
  .gap-xs { gap: var(--spacing-xs); }
  .gap-sm { gap: var(--spacing-sm); }
  .gap-md { gap: var(--spacing-md); }
  .gap-lg { gap: var(--spacing-lg); }
  
  /* Responsive utilities */
  @media (min-width: 640px) {
    .sm\:flex { display: flex; }
    .sm\:hidden { display: none; }
  }
  
  @media (min-width: 768px) {
    .md\:flex { display: flex; }
    .md\:hidden { display: none; }
  }
  
  @media (min-width: 1024px) {
    .lg\:flex { display: flex; }
    .lg\:hidden { display: none; }
  }