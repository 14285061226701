/* src/pages/EventDetailPage.css */
.event-detail-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: var(--viterbo-off-white);
}

.share-button {
    background: none;
    border: none;
    color: var(--viterbo-white);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: var(--spacing-xs);
    border-radius: 50%;
    transition: background-color var(--transition-normal);
}

.share-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.share-options {
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: var(--viterbo-white);
    border-radius: var(--border-radius-md);
    box-shadow: var(--shadow-lg);
    z-index: var(--z-index-popover);
    overflow: hidden;
    animation: fadeInDown 0.3s ease-in-out;
}

@keyframes fadeInDown {
    from { 
        opacity: 0;
        transform: translateY(-10px);
    }
    to { 
        opacity: 1;
        transform: translateY(0);
    }
}

.share-option {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    padding: var(--spacing-md);
    width: 100%;
    background: none;
    border: none;
    cursor: pointer;
    font-size: var(--font-size-sm);
    color: var(--viterbo-blue);
    transition: background-color var(--transition-normal);
    text-align: left;
}

.share-option:hover {
    background-color: var(--viterbo-off-white);
}

.share-option:not(:last-child) {
    border-bottom: 1px solid var(--viterbo-light-gray);
}

.event-detail-content {
    flex: 1;
    padding: var(--spacing-md);
    padding-bottom: 80px; /* Space for mobile navigation */
}

.event-detail-loading,
.event-detail-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    background-color: var(--viterbo-white);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--shadow-md);
    padding: var(--spacing-xl);
    margin-top: var(--spacing-md);
}

.event-detail-loading .loading-spinner {
    width: 40px;
    height: 40px;
    margin-bottom: var(--spacing-md);
}

.event-detail-error {
    color: var(--viterbo-red);
}

.event-detail-card {
    background-color: var(--viterbo-white);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--shadow-md);
    overflow: hidden;
    margin-top: var(--spacing-md);
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.event-detail-header {
    padding: var(--spacing-lg);
    background-color: var(--viterbo-white);
}

.event-detail-title {
    margin: 0 0 var(--spacing-md) 0;
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
    color: var(--viterbo-blue);
    line-height: 1.3;
}

.event-detail-dates {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
}

.event-date-item {
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-sm);
    color: var(--viterbo-red);
    font-size: var(--font-size-sm);
}

.event-date-details {
    display: flex;
    flex-direction: column;
}

.event-date-day {
    font-weight: var(--font-weight-medium);
}

.event-date-time {
    color: var(--viterbo-gray);
}

.event-detail-image {
    width: 100%;
    height: auto;
    max-height: 400px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.event-detail-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.event-detail-body {
    padding: var(--spacing-lg);
}

.event-detail-description {
    font-size: var(--font-size-base);
    line-height: 1.6;
    color: var(--viterbo-dark-gray);
    white-space: pre-line;
}

.event-link {
    color: var(--viterbo-blue);
    text-decoration: none;
    font-weight: var(--font-weight-medium);
    word-break: break-all;
}

.event-link:hover {
    text-decoration: underline;
}

.event-detail-links {
    padding: 0 var(--spacing-lg) var(--spacing-lg);
    border-top: 1px solid var(--viterbo-light-gray);
    margin-top: var(--spacing-md);
}

.event-detail-links h3 {
    font-size: var(--font-size-md);
    color: var(--viterbo-blue);
    margin-bottom: var(--spacing-sm);
    font-weight: var(--font-weight-bold);
}

.event-detail-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.event-detail-links li {
    margin-bottom: var(--spacing-sm);
}

.event-detail-link {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    color: var(--viterbo-blue);
    text-decoration: none;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    transition: color var(--transition-normal);
}

.event-detail-link:hover {
    color: var(--viterbo-blue-light);
    text-decoration: underline;
}

.btn-back-to-events {
    display: block;
    margin: var(--spacing-lg) auto;
    background-color: var(--viterbo-blue);
    color: var(--viterbo-white);
    padding: var(--spacing-md) var(--spacing-lg);
    border: none;
    border-radius: var(--border-radius-md);
    font-size: var(--font-size-base);
    cursor: pointer;
    font-weight: var(--font-weight-medium);
    transition: background-color var(--transition-normal), transform var(--transition-fast);
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-back-to-events:hover {
    background-color: var(--viterbo-blue-light);
    transform: translateY(-2px);
}

.btn-back-to-events:active {
    transform: translateY(0);
}

/* Media queries for responsiveness */
@media (min-width: 768px) {
    .event-detail-card {
        max-width: 800px;
        margin: var(--spacing-md) auto;
    }

    .event-detail-image {
        max-height: 450px;
    }

    .btn-back-to-events {
        max-width: 300px;
    }
}