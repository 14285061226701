/* src/pages/EventsPage.css */
.events-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: var(--viterbo-off-white);
}

.back-button {
    background: none;
    border: none;
    color: var(--viterbo-white);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: var(--spacing-sm);
    padding: var(--spacing-xs);
    border-radius: 50%;
    transition: background-color var(--transition-normal);
}

.back-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.events-content {
    flex: 1;
    padding: var(--spacing-md);
    padding-bottom: 80px; /* Space for mobile navigation */
}

.events-loading,
.events-error,
.events-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    background-color: var(--viterbo-white);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--shadow-md);
    padding: var(--spacing-xl);
    margin-top: var(--spacing-md);
}

.events-loading .loading-spinner {
    width: 40px;
    height: 40px;
    margin-bottom: var(--spacing-md);
}

.events-error {
    color: var(--viterbo-red);
}

.btn-retry {
    background-color: var(--viterbo-blue);
    color: var(--viterbo-white);
    padding: var(--spacing-md);
    border: none;
    border-radius: var(--border-radius-md);
    font-size: var(--font-size-base);
    cursor: pointer;
    margin-top: var(--spacing-md);
    font-weight: var(--font-weight-medium);
    transition: background-color var(--transition-normal);
}

.btn-retry:hover {
    background-color: var(--viterbo-blue-light);
}

.events-empty {
    color: var(--viterbo-gray);
}

.events-empty svg {
    width: 48px;
    height: 48px;
    color: var(--viterbo-red);
    margin-bottom: var(--spacing-md);
}

.events-list-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
    margin-top: var(--spacing-md);
}

.month-header {
    font-size: var(--font-size-xl);
    color: var(--viterbo-blue);
    margin-bottom: var(--spacing-md);
    font-weight: var(--font-weight-bold);
    position: relative;
    padding-bottom: var(--spacing-sm);
}

.month-header::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 3px;
    background-color: var(--viterbo-red);
    border-radius: 3px;
}

.events-list {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
}

.event-card {
    display: flex;
    background-color: var(--viterbo-white);
    border-radius: var(--border-radius-lg);
    overflow: hidden;
    box-shadow: var(--shadow-md);
    cursor: pointer;
    transition: transform var(--transition-normal), box-shadow var(--transition-normal);
}

.event-card:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-lg);
    }

.event-card-image {
    width: 120px;
    min-width: 120px;
    height: 120px;
    overflow: hidden;
}

.event-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform var(--transition-normal);
}

.event-card:hover .event-card-image img {
    transform: scale(1.05);
}

.event-card-content {
    padding: var(--spacing-md);
    flex: 1;
    display: flex;
    flex-direction: column;
}

.event-card-title {
    margin: 0;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-bold);
    color: var(--viterbo-blue);
    margin-bottom: var(--spacing-sm);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.event-card-date {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    font-size: var(--font-size-sm);
    color: var(--viterbo-red);
    margin-bottom: var(--spacing-sm);
}

.event-card-description {
    margin: 0;
    font-size: var(--font-size-sm);
    color: var(--viterbo-gray);
    line-height: 1.4;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Media queries for responsiveness */
@media (min-width: 768px) {
    .events-list-container {
        margin: var(--spacing-md) auto;
        max-width: 800px;
    }

.event-card-image {
    width: 180px;
    min-width: 180px;
    height: 160px;
}

.event-card-content {
    padding: var(--spacing-lg);
}

.event-card-title {
    font-size: var(--font-size-lg);
}

.event-card-description {
        -webkit-line-clamp: 3;
    }
}

@media (max-width: 480px) {
.event-card {
    flex-direction: column;
}

.event-card-image {
    width: 100%;
    height: 160px;
}

.event-card-content {
        padding: var(--spacing-md);
    }
}