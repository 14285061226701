/* src/components/ExternalRedirect.css */
.external-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--viterbo-white);
}

.external-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--viterbo-blue);
  color: var(--viterbo-white);
  padding: var(--spacing-sm) var(--spacing-md);
  z-index: var(--z-index-sticky);
}

.external-header-left {
  display: flex;
  align-items: center;
}

.external-header-right {
  display: flex;
  align-items: center;
}

.back-button,
.close-button {
  background: none;
  border: none;
  color: var(--viterbo-white);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xs);
  border-radius: var(--border-radius-full);
  transition: background-color var(--transition-normal);
}

.back-button:hover,
.close-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.external-header h1 {
  font-size: var(--font-size-lg);
  margin: 0 var(--spacing-md);
  font-weight: var(--font-weight-bold);
}

.external-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md);
  overflow-y: auto;
}

.external-info {
  max-width: 600px;
  width: 100%;
  background-color: var(--viterbo-white);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md);
  padding: var(--spacing-xl);
  text-align: center;
  animation: fadeInUp 0.5s ease-in-out;
}

@keyframes fadeInUp {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.external-logo {
  width: 80px;
  height: 80px;
  margin-bottom: var(--spacing-lg);
}

.external-info h2 {
  color: var(--viterbo-blue);
  margin-bottom: var(--spacing-md);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
}

.external-description {
  color: var(--viterbo-dark-gray);
  margin-bottom: var(--spacing-lg);
  font-size: var(--font-size-md);
  line-height: 1.6;
}

.external-url {
  background-color: var(--viterbo-off-white);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-lg);
  word-break: break-all;
  font-family: monospace;
  font-size: var(--font-size-sm);
  color: var(--viterbo-blue);
}

.external-warning {
  display: flex;
  align-items: flex-start;
  background-color: rgba(158, 27, 52, 0.1);
  color: var(--viterbo-red);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  margin-bottom: var(--spacing-lg);
  text-align: left;
}

.external-warning svg {
  flex-shrink: 0;
  margin-right: var(--spacing-md);
  margin-top: 2px;
}

.external-warning p {
  margin: 0;
  font-size: var(--font-size-sm);
  line-height: 1.5;
}

.external-button {
  background-color: var(--viterbo-red);
  color: var(--viterbo-white);
  padding: var(--spacing-md) var(--spacing-lg);
  border: none;
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  margin: 0 auto;
  transition: background-color var(--transition-normal), transform var(--transition-fast);
  width: 100%;
  max-width: 300px;
}

.external-button:hover {
  background-color: var(--viterbo-red-light);
  transform: translateY(-2px);
}

.external-button:active {
  transform: translateY(0);
}

.back-dashboard-button {
  background: none;
  border: none;
  color: var(--viterbo-blue);
  cursor: pointer;
  margin-top: var(--spacing-md);
  font-size: var(--font-size-sm);
  text-decoration: underline;
  transition: color var(--transition-normal);
}

.back-dashboard-button:hover {
  color: var(--viterbo-blue-light);
}

/* Responsive styles */
@media (max-width: 480px) {
  .external-header h1 {
    font-size: var(--font-size-base);
  }
  
  .external-info {
    padding: var(--spacing-lg);
  }
  
  .external-info h2 {
    font-size: var(--font-size-lg);
  }
  
  .external-description {
    font-size: var(--font-size-base);
  }
  
  .external-logo {
    width: 60px;
    height: 60px;
  }
}