/* src/components/UserCourseRegistration.css */

/* Form Styles */
.moodle-form {
    margin-bottom: var(--spacing-lg);
  }
  
  .form-group {
    margin-bottom: var(--spacing-md);
  }
  
  .form-group label {
    display: block;
    margin-bottom: var(--spacing-xs);
    font-weight: var(--font-weight-medium);
    color: var(--viterbo-blue);
  }
  
  .form-input {
    width: 100%;
    padding: var(--spacing-md);
    border-radius: var(--border-radius-md);
    border: 1px solid var(--viterbo-light-gray);
    font-size: var(--font-size-base);
    transition: border-color var(--transition-normal), box-shadow var(--transition-normal);
  }
  
  .form-input:focus {
    outline: none;
    border-color: var(--viterbo-blue-light);
    box-shadow: 0 0 0 3px rgba(0, 38, 81, 0.2);
  }
  
  .error-message {
    background-color: rgba(158, 27, 52, 0.1);
    color: var(--viterbo-red);
    padding: var(--spacing-md);
    border-radius: var(--border-radius-md);
    margin: var(--spacing-md) 0;
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
  }
  
  /* User Info Styles */
  .moodle-user-info, 
  .courses-container {
    margin-top: var(--spacing-md);
    padding-top: var(--spacing-md);
    border-top: 1px solid var(--viterbo-light-gray);
  }
  
  .moodle-user-info h3, 
  .courses-container h3 {
    color: var(--viterbo-blue);
    margin-bottom: var(--spacing-md);
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
  }
  
  .course-count {
    color: var(--viterbo-gray);
    margin-bottom: var(--spacing-md);
  }
  
  .user-profile-image {
    margin-top: var(--spacing-md);
    display: flex;
    justify-content: center;
  }
  
  .user-profile-image img {
    border-radius: var(--border-radius-full);
    max-width: 100px;
    border: 3px solid var(--viterbo-white);
    box-shadow: var(--shadow-md);
  }
  
  /* Course List Styles */
  .course-list {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
  }
  
  .course-item {
    background-color: var(--viterbo-off-white);
    border-radius: var(--border-radius-md);
    border-left: 4px solid var(--viterbo-red);
    overflow: hidden;
  }
  
  .course-header {
    /*background-color: var(--viterbo-blue-light);*/
    color: var(--viterbo-white);
    padding: var(--spacing-sm) var(--spacing-md);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .course-name {
    margin: 0;
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    font-size: var(--font-size-md);
  }
  
  .course-id {
    font-size: var(--font-size-sm);
    opacity: 0.8;
  }
  
  .course-details {
    padding: var(--spacing-md);
  }
  
  .course-info {
    margin-bottom: var(--spacing-md);
    font-size: var(--font-size-sm);
    line-height: 1.6;
  }
  
  /* Meeting Details Styles */
  .meeting-details h5 {
    color: var(--viterbo-blue);
    margin-bottom: var(--spacing-sm);
    font-size: var(--font-size-md);
  }
  
  .meeting-item {
    background-color: var(--viterbo-white);
    border-radius: var(--border-radius-sm);
    padding: var(--spacing-md);
    margin-bottom: var(--spacing-md);
    box-shadow: var(--shadow-sm);
  }
  
  .meeting-type {
    display: inline-block;
    background-color: var(--viterbo-red);
    color: var(--viterbo-white);
    padding: var(--spacing-xs) var(--spacing-sm);
    border-radius: var(--border-radius-sm);
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--spacing-sm);
  }
  
  .meeting-days, 
  .meeting-method {
    margin-bottom: var(--spacing-sm);
    font-size: var(--font-size-sm);
  }
  
  .meeting-time, 
  .meeting-location {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    margin-bottom: var(--spacing-sm);
    font-size: var(--font-size-sm);
  }
  
  .no-meeting-details {
    background-color: var(--viterbo-white);
    border-radius: var(--border-radius-sm);
    padding: var(--spacing-md);
    font-style: italic;
    color: var(--viterbo-gray);
  }
  
  .course-lookup-container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .back-button {
    margin-top: var(--spacing-lg);
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .course-header {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .course-id {
      margin-top: var(--spacing-xs);
    }
  }