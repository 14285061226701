/* CourseSchedule.css */

.course-schedule {
    width: 100%;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  .schedule-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-md);
    flex-wrap: wrap;
    gap: var(--spacing-md);
  }
  
  .schedule-header h2 {
    margin: 0;
    color: var(--viterbo-blue);
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
  }
  
  .term-selector {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
  }
  
  .term-selector label {
    font-weight: var(--font-weight-medium);
    color: var(--viterbo-blue);
  }
  
  .term-select {
    padding: var(--spacing-sm) var(--spacing-md);
    border-radius: var(--border-radius-md);
    border: 1px solid var(--viterbo-light-gray);
    background-color: var(--viterbo-white);
    color: var(--viterbo-blue);
    font-weight: var(--font-weight-medium);
    cursor: pointer;
    transition: border-color var(--transition-normal), box-shadow var(--transition-normal);
  }
  
  .term-select:focus {
    outline: none;
    border-color: var(--viterbo-blue);
    box-shadow: 0 0 0 2px rgba(0, 38, 81, 0.2);
  }
  
  .schedule-days {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
  }
  
  .schedule-day {
    background-color: var(--viterbo-white);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--shadow-md);
    overflow: hidden;
  }
  
  .day-header {
    background-color: var(--viterbo-blue);
    color: var(--viterbo-white);
    padding: var(--spacing-sm) var(--spacing-md);
    margin: 0;
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
  }
  
  .day-courses {
    padding: var(--spacing-md);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
  }
  
  .course-card {
    background-color: var(--viterbo-off-white);
    border-radius: var(--border-radius-md);
    padding: var(--spacing-md);
    transition: transform var(--transition-normal), box-shadow var(--transition-normal);
    border-left: 4px solid var(--viterbo-red);
  }
  
  .course-card:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow-md);
  }
  
  .course-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-sm);
  }
  
  .course-name {
    margin: 0;
    color: var(--viterbo-blue);
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-bold);
  }
  
  .course-type {
    background-color: var(--viterbo-blue);
    color: var(--viterbo-white);
    padding: var(--spacing-xs) var(--spacing-sm);
    border-radius: var(--border-radius-full);
    font-size: var(--font-size-xs);
    font-weight: var(--font-weight-medium);
    text-transform: uppercase;
  }
  
  .course-details {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
    color: var(--viterbo-dark-gray);
  }
  
  .course-time, .course-location, .course-method {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
    font-size: var(--font-size-sm);
  }
  
  .course-time svg, .course-location svg, .course-method svg {
    color: var(--viterbo-red);
    flex-shrink: 0;
  }
  
  .schedule-loading, .schedule-error, .no-courses {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-xl);
    background-color: var(--viterbo-white);
    border-radius: var(--border-radius-lg);
    box-shadow: var(--shadow-md);
    text-align: center;
  }
  
  .schedule-loading .loading-spinner {
    margin-bottom: var(--spacing-md);
    width: 30px;
    height: 30px;
  }
  
  .schedule-error h3 {
    color: var(--viterbo-red);
    margin-bottom: var(--spacing-md);
  }
  
  .no-courses p {
    color: var(--viterbo-gray);
    margin-bottom: var(--spacing-md);
  }
  
  /* Responsive styles */
  @media (min-width: 768px) {
    .course-details {
      flex-direction: row;
      flex-wrap: wrap;
    }
    
    .course-time, .course-location, .course-method {
      margin-right: var(--spacing-md);
    }
  }
  
  @media (max-width: 576px) {
    .schedule-header {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .term-selector {
      width: 100%;
    }
    
    .term-select {
      width: 100%;
    }
    
    .course-header {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .course-type {
      margin-top: var(--spacing-xs);
    }
  }