:root {
  --mobile-nav-height: 70px; /* Base height - adjust as needed */
}

.dashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--viterbo-off-white);
}

.dashboard-header-right {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}

.install-button-container {
  width: auto;
}

.btn-install {
  white-space: nowrap;
  margin: 0;
  padding: var(--spacing-sm) var(--spacing-md);
  height: auto;
  min-height: 36px;
}

.btn-install:disabled {
  opacity: 0.7;
  cursor: default;
}

/* Consolidated mobile nav styles */
.mobile-nav {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--viterbo-white);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  height: var(--mobile-nav-height);
  padding-bottom: env(safe-area-inset-bottom, 0); /* For iOS devices with notches */
}

.mobile-nav-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-sm) 0;
  color: var(--viterbo-gray);
  text-decoration: none;
  font-size: var(--font-size-xs);
  transition: color var(--transition-normal);
  background: none;
  border: none;
  cursor: pointer;
}

.mobile-nav-item.active {
  color: var(--viterbo-red);
}

.mobile-nav-icon {
  margin-bottom: var(--spacing-xs);
  font-size: var(--font-size-lg);
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--viterbo-blue);
  color: var(--viterbo-white);
  padding: var(--spacing-sm) var(--spacing-md);
  box-shadow: var(--shadow-md);
  position: sticky;
  top: 0;
  z-index: var(--z-index-sticky);
}

.dashboard-logo {
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--spacing-sm);
  border-radius: 0;
  background-color: transparent;
}

.dashboard-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* Remove padding */
  padding: 0;
}

.dashboard-brand {
  display: flex;
  align-items: center;
}

.dashboard-brand h1 {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  margin: 0;
}

.dashboard-user-info {
  display: flex;
  align-items: center;
  font-size: var(--font-size-sm);
}

.dashboard-welcome {
  font-weight: var(--font-weight-medium);
  margin-right: var(--spacing-md);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.btn-logout {
  background-color: var(--viterbo-red);
  color: var(--viterbo-white);
  padding: var(--spacing-sm) var(--spacing-md);
  border: none;
  border-radius: var(--border-radius-md);
  font-size: var(--font-size-sm);
  cursor: pointer;
  font-weight: var(--font-weight-medium);
  transition: background-color var(--transition-normal), transform var(--transition-fast);
}

.btn-logout:hover {
  background-color: var(--viterbo-red-light);
  transform: translateY(-2px);
}

.btn-logout:active {
  transform: translateY(0);
}

/* Content Area */
.dashboard-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: var(--spacing-md);
  gap: var(--spacing-md);
  overflow-y: auto;
  scroll-behavior: smooth;
  padding-bottom: calc(var(--mobile-nav-height) + 20px); 
}

.events-content,
.event-detail-content {
  padding-bottom: calc(var(--mobile-nav-height) + 20px);
}

.dashboard-greeting {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  color: var(--viterbo-blue);
  margin-top: var(--spacing-md);
  margin-bottom: var(--spacing-sm);
  width: 100%;
}

.dashboard-card {
  background: var(--viterbo-white);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-lg);
  box-shadow: var(--shadow-md);
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
  animation: fadeInUp 0.5s ease-in-out;
  width: 100%;
  margin-bottom: var(--spacing-md);
  scroll-margin-top: 80px; /* Add scroll margin to account for sticky header */
}

@keyframes fadeInUp {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.dashboard-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.dashboard-card h2 {
  color: var(--viterbo-blue);
  margin-bottom: var(--spacing-md);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

.dashboard-card h2 svg {
  color: var(--viterbo-red);
}

.dashboard-list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: var(--font-size-base);
  line-height: 1.6;
  color: var(--viterbo-dark-gray);
}

.dashboard-list li {
  margin-bottom: var(--spacing-md);
  padding-bottom: var(--spacing-md);
  border-bottom: 1px solid var(--viterbo-light-gray);
  display: flex;
  align-items: flex-start;
}

.dashboard-list li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.dashboard-list-icon {
  margin-right: var(--spacing-sm);
  color: var(--viterbo-red);
  flex-shrink: 0;
}

.dashboard-list-content {
  flex-grow: 1;
}

.dashboard-list-time {
  font-weight: var(--font-weight-bold);
  color: var(--viterbo-blue);
  margin-bottom: var(--spacing-xs);
}

.dashboard-list-location {
  color: var(--viterbo-gray);
  font-size: var(--font-size-sm);
  margin-top: var(--spacing-xs);
}

/* Calendar styles */
.calendar-container {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-sm);
}

.calendar-date {
  background: var(--viterbo-off-white);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  flex: 1 1 calc(33% - var(--spacing-sm));
  min-width: 90px;
  box-shadow: var(--shadow-sm);
  text-align: center;
  font-size: var(--font-size-sm);
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
}

.calendar-date:hover {
  transform: translateY(-3px);
  box-shadow: var(--shadow-md);
}

.calendar-day {
  display: block;
  font-weight: var(--font-weight-bold);
  color: var(--viterbo-blue);
  text-transform: uppercase;
  font-size: var(--font-size-xs);
}

.calendar-num {
  display: block;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  margin: var(--spacing-xs) 0;
  color: var(--viterbo-black);
  line-height: 1.2;
}

.calendar-event {
  display: block;
  font-size: var(--font-size-xs);
  color: var(--viterbo-red);
  font-weight: var(--font-weight-medium);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.calendar-date.has-event {
  border-left: 3px solid var(--viterbo-red);
}

.btn-refresh {
  background-color: var(--viterbo-blue);
  color: var(--viterbo-white);
  padding: var(--spacing-sm);
  border: none;
  border-radius: var(--border-radius-full);
  font-size: var(--font-size-sm);
  cursor: pointer;
  font-weight: var(--font-weight-medium);
  transition: background-color var(--transition-normal), transform var(--transition-fast);
  margin-right: var(--spacing-sm);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
}

.btn-refresh:hover {
  background-color: var(--viterbo-blue-light);
  transform: rotate(30deg);
}

.btn-refresh:active {
  transform: rotate(90deg);
}

.btn-refresh:disabled {
  background-color: var(--viterbo-gray);
  cursor: not-allowed;
  opacity: 0.7;
}

.loading-indicator {
  display: inline-flex;
  align-items: center;
  margin-left: var(--spacing-md);
}

.loading-spinner-small {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(158, 27, 52, 0.3);
  border-radius: 50%;
  border-top-color: var(--viterbo-red);
  animation: spin 1s ease-in-out infinite;
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.quick-links {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  margin-top: var(--spacing-md);
}

.quick-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  background-color: var(--viterbo-off-white);
  transition: transform var(--transition-normal), box-shadow var(--transition-normal);
  color: var(--viterbo-dark-gray);
  border-left: 4px solid var(--viterbo-red);
}

.quick-link:hover {
  transform: translateX(5px);
  box-shadow: var(--shadow-md);
}

.quick-link-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  height: 48px;
  background-color: var(--viterbo-blue);
  border-radius: var(--border-radius-full);
  margin-right: var(--spacing-md);
  color: var(--viterbo-white);
}

.quick-link-content {
  flex: 1;
}

.quick-link-content h3 {
  margin: 0 0 var(--spacing-xs) 0;
  color: var(--viterbo-blue);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-bold);
}

.quick-link-content p {
  margin: 0;
  font-size: var(--font-size-sm);
  color: var(--viterbo-gray);
  text-align: left;
}

.external-indicator {
  display: inline-block;
  font-size: var(--font-size-xs);
  color: var(--viterbo-red);
  margin-top: var(--spacing-xs);
  padding: 2px 5px;
  border-radius: var(--border-radius-sm);
  background-color: rgba(158, 27, 52, 0.1);
}

.internal-indicator {
  display: inline-block;
  font-size: var(--font-size-xs);
  color: var(--viterbo-blue);
  margin-top: var(--spacing-xs);
  padding: 2px 5px;
  border-radius: var(--border-radius-sm);
  background-color: rgba(0, 38, 81, 0.1);
}

/* iOS instructions styling updates */
.ios-instructions {
  background-color: var(--viterbo-off-white);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  margin-top: var(--spacing-md);
  text-align: left;
  box-shadow: var(--shadow-md);
  animation: slideDown 0.3s ease-in-out;
}

.ios-app-icon {
  display: flex;
  justify-content: center;
  margin: var(--spacing-md) 0;
}

.ios-app-icon img {
  border-radius: 12px;
  box-shadow: var(--shadow-md);
}

/* Responsive Layout */
@media (min-width: 768px) {
  .dashboard-content {
    /* Keep the cards stacked in desktop view just like mobile */
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  .dashboard-greeting {
    width: 100%;
    margin-bottom: var(--spacing-md);
    text-align: center;
  }
  
  p {
    width: 100%;
    text-align: center;
    margin-bottom: var(--spacing-lg);
  }
  
  .install-button-container {
    width: 100%;
  }
  
  .dashboard-card {
    width: 100%;
    max-width: 800px;
    margin-bottom: var(--spacing-lg);
  }
  
  .mobile-nav {
    display: none;
  }

  .quick-links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-md);
  }

  .hamburger-button {
    display: flex;
  }
}

/* Still allowing for wider cards on extra large screens */
@media (min-width: 1200px) {
  .dashboard-card {
    max-width: 900px;
  }
}

@media (min-width: 1024px) {
  .dashboard-card {
    width: calc(33.333% - var(--spacing-md));
  }

  .quick-links {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Extra small screens */
@media (max-width: 480px) {
  :root {
    --mobile-nav-height: 80px; /* Taller on smaller screens */
  }

  .dashboard-welcome {
    max-width: 150px;
  }
  
  .calendar-date {
    flex: 1 1 calc(50% - var(--spacing-sm));
  }
  
  .dashboard-header {
    padding: var(--spacing-sm);
  }
  
  .dashboard-header-right {
    gap: var(--spacing-xs);
  }

  .dashboard-brand h1 {
    font-size: var(--font-size-base);
  }
  
  .dashboard-content {
    padding: var(--spacing-sm);
    padding-bottom: calc(var(--mobile-nav-height) + 20px);
  }
  
  .dashboard-card {
    padding: var(--spacing-md);
  }

  .btn-refresh {
    height: 32px;
    width: 32px;
    padding: var(--spacing-xs);
  }
}

@media (max-width: 360px) {
  :root {
    --mobile-nav-height: 90px; /* Even taller on very small screens */
  }
  /* For very small screens, reduce padding */
  .mobile-nav-item {
    padding: var(--spacing-xs) 0;
  }
  
  /* Make text smaller */
  .mobile-nav-item span {
    font-size: 10px;
  }
  
  .dashboard-content,
  .events-content,
  .event-detail-content {
    padding-bottom: calc(var(--mobile-nav-height) + 25px);
  }
}

/* Extra safe spacing for very small screens */
@media (max-width: 320px) {
  .dashboard-content,
  .events-content,
  .event-detail-content {
    padding-bottom: calc(var(--mobile-nav-height) + 30px);
  }
}