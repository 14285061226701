/* src/components/DesktopNav.css */
.desktop-nav {
    display: none; /* Hidden on mobile */
    background-color: var(--viterbo-white);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: var(--spacing-sm) var(--spacing-md);
    position: sticky;
    top: 60px; /* Adjust based on header height */
    /*z-index: var(--z-index-sticky);*/
  }
  
  .desktop-nav-item {
    display: inline-flex;
    align-items: center;
    color: var(--viterbo-blue);
    text-decoration: none;
    padding: var(--spacing-sm) var(--spacing-md);
    margin-right: var(--spacing-md);
    border-radius: var(--border-radius-md);
    transition: background-color var(--transition-normal);
    font-weight: var(--font-weight-medium);
  }
  
  .desktop-nav-item:hover {
    background-color: var(--viterbo-off-white);
  }
  
  .desktop-nav-item.active {
    background-color: var(--viterbo-blue);
    color: var(--viterbo-white);
  }
  
  .desktop-nav-item.active svg {
    color: var(--viterbo-white);
  }
  
  .desktop-nav-item svg {
    margin-right: var(--spacing-sm);
    color: var(--viterbo-red);
  }
  
  /* Show on desktop */
  @media (min-width: 768px) {
    .desktop-nav {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }